import { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'

const TrustpilotWidget = () => {
  const [isMounted, setIsMounted] = useState(false)
  const scriptLoadedRef = useRef(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && !scriptLoadedRef.current) {
      scriptLoadedRef.current = true
      var aScript = document.createElement('script')
      aScript.type = 'text/javascript'
      aScript.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      aScript.async = true
      aScript.id = 'trustpilot-script'
      aScript.onerror = (e) => {
        console.error('Error loading Trustpilot widget:', e)
      }
      aScript.onload = function () {
        setIsMounted(true)
        setTimeout(() => {
          const trustbox = document.getElementById(
            isMobile ? 'trustbox-2' : 'trustbox',
          )
          if (trustbox && window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustbox)
          }
        }, 100)
      }
      document.head.appendChild(aScript)
    }

    return () => {
      const scriptElement = document.getElementById('trustpilot-script')
      if (scriptElement && scriptElement.parentNode) {
        scriptElement.parentNode.removeChild(scriptElement)
      }
    }
  }, [])

  if (!isMounted) return null

  return (
    <>
      <style>
        {`
          .trustpilot-inverted {
            filter: invert(1) hue-rotate(180deg) !important;
          }
          .trustpilot-widget,
          .trustpilot-widget iframe {
            background: black !important;
            color-scheme: dark !important;
          }
        `}
      </style>
      <div className='w-full'>
        {!isMobile ? (
          <div
            className='trustpilot-inverted'
            id='trustbox'
            data-locale='en-US'
            data-template-id='5419b6ffb0d04a076446a9af'
            data-businessunit-id='6313b78e2e52b38231862470'
            data-style-height='20px'
            data-style-width='100%'
            // data-theme='dark'
          ></div>
        ) : (
          <div
            className='mt-4 trustpilot-inverted'
            id='trustbox-2'
            data-locale='en-US'
            data-template-id='53aa8807dec7e10d38f59f32'
            data-businessunit-id='6313b78e2e52b38231862470'
            data-style-height='150px'
            data-style-width='100%'
            // data-theme='dark'
          ></div>
        )}
      </div>
    </>
  )
}

export default TrustpilotWidget
