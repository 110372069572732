import Image, { StaticImageData } from 'next/image'
import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ProductResponse } from '../../types/products/product-response'
import ClockGrayIcon from '../../public/static/clock_gray.svg'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { formatDuration_dash } from '../../common/utils/url.utils'
import { waitingTime } from '../../common/utils/time.utils'
import { LocalizedPrice } from '../../types/prices/LocalizedPrice'
import PricePart from './PricePart'
import { UserResponse } from '../../types/users/user-response'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { isOpenVoiceChat } from './voice-chat/VoiceChatPart'
import { useRecoilState } from 'recoil'
import { enableVoiceChatOffToggleButtonState } from '../../common/atoms/checkout'
import { ImageAssetResponse } from '../../types/assets/responses/image-asset-response'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(localizedFormat)

type Props = {
  prices: LocalizedPrice[] | undefined
  product: ProductResponse
  isLoading: boolean
  imageUrl: string | ImageAssetResponse | StaticImageData
  currencyId?: number | null
  currencySymbol?: string | null
  teammate?: UserResponse | null
  decoration?: StaticImageData
  game: CategoryResponse
}

export default function ProductCardV2({
  prices,
  product,
  isLoading,
  imageUrl,
  currencyId,
  teammate,
  decoration,
  game,
}: Props) {
  // const { user } = useUser()
  const [enableVoiceChatOffToggleButton, setEnableVoiceChatOffToggleButton] =
    useRecoilState(enableVoiceChatOffToggleButtonState)
  const [voiceCheckEnable, setVoiceEnableCheck] =
    useRecoilState(isOpenVoiceChat)

  const isHoliday = false

  const price_dash =
    isHoliday || (!voiceCheckEnable && product.mode === 'competitive')

  const [estTime, setEstTime] = useState(
    `${waitingTime(product, true, true)} min away`,
  )

  const charge = product.localizedPrices?.charge?.find((charge) => {
    return charge.currency?.id === currencyId
  })

  let price = +(charge?.displayPrice ?? charge?.price ?? 10000).toFixed(2)

  if (prices) {
    Object.values(prices).forEach((pricing) => {
      if (pricing.productId === product.id) {
        const charge = pricing.charge?.find((charge) => {
          return charge.currency?.id === currencyId
        })

        price = +(charge?.displayPrice ?? charge?.price ?? 10000).toFixed(2)
      }
    })
  }

  function calculateFakePrice(actualPrice: number): number {
    // Calculate the base fake price (actual price / 0.85)
    const baseFakePrice = actualPrice / 0.8
    // Round up to the next whole number and subtract 0.01
    return Math.ceil(baseFakePrice) - 0.01
  }

  function adjustBFPrice(originalPrice: number, checked?: boolean): number {
    if (originalPrice === 0) {
      return 0
    }

    if (!voiceCheckEnable && checked) {
      return originalPrice - 0.25
    }

    // let discountedPrice = +(originalPrice * 0.95).toFixed(2)

    let discountedPrice = Math.floor(originalPrice * 100 * 1) / 100

    if (discountedPrice === 0) {
      return 0
    }

    return discountedPrice
  }

  useEffect(() => {
    setEstTime(`${waitingTime(product, true)} min away`)
  }, [])

  return (
    <RadioGroup.Option
      key={product.uniqueId}
      value={product}
      className={({ checked }) =>
        `w-full ${checked ? (product?.slug?.includes('xmas') ? 'bg-[#FFB535] bg-opacity-30' : 'bg-[#212900]') : ''} relative cursor-pointer ${
          isLoading ? 'animate-pulsing' : ''
        } ${price >= 10000 ? 'opacity-20' : ''} outline-none`
      }
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
    >
      {({ checked }) => (
        <div className={'flex flex-col px-3 py-3'}>
          <div
            className={`flex w-full flex-row items-center justify-between ${enableVoiceChatOffToggleButton && 'pb-3'}`}
          >
            <div
              className={`h-4 w-4 shrink-0 select-none rounded-full
            ${
              checked
                ? product?.slug?.includes('xmas')
                  ? 'border-3 border-[#FFB535] bg-[#03060D]'
                  : 'border-3 border-bung-400 bg-[#03060D]'
                : 'border-2 border-darul-500 '
            }
          `}
            />

            <div
              className={`${decoration && game?.key !== 'egirls' ? 'relative border-2 p-0.5 rounded-full border-thresh-500 ml-5' : 'ml-6'} shrink-0 `}
            >
              <div className={'relative rounded-full h-9 w-9'}>
                <Image
                  src={teammate?.avatar?.imageUrl ?? imageUrl}
                  alt={product.title}
                  fill={true}
                  className={`${teammate?.avatar?.imageUrl ? 'rounded-full object-cover' : 'object-contain'}`}
                />
              </div>

              {decoration && game?.key !== 'egirls' && (
                <div
                  className={'h-5 w-4 rounded-full absolute -bottom-1 right-0'}
                >
                  <Image
                    src={decoration}
                    alt={product.title + 'decoration'}
                    fill={true}
                    className={'object-cover'}
                  />
                </div>
              )}
            </div>

            <div
              className={
                'ml-3.5 mr-3 flex w-full flex-row items-start justify-between gap-1.5'
              }
            >
              <div className={'flex flex-col'}>
                <div className={'flex flex-row items-center gap-1'}>
                  <h2 className={'text-lg-medium text-color-gray5'}>
                    {product.title}
                  </h2>
                </div>

                <h3
                  className={`text-secondary-normal text-[#C8CFDE] ${
                    checked ? '' : 'line-clamp-2'
                  }`}
                >
                  {product.about}
                </h3>

                {product.seoSlug && (
                  <a
                    className={
                      'text-secondary-medium w-22 truncate text-[#81A900]'
                    }
                    href={`/${product.categoryKey}/${game?.seoSlug}/${product.seoSlug}`}
                  >
                    See details ›
                  </a>
                )}
              </div>

              <div className={'flex shrink-0 flex-col items-end truncate'}>
                <div className={'flex flex-row items-center font-medium'}>
                  {(product.slug?.includes('xmas') ||
                    product.slug?.includes('bundle')) && (
                    <span
                      className={`text-secondary-normal text-fizz-300 line-through mr-1 decoration-gnar-700 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
                    >
                      <PricePart
                        price={calculateFakePrice(price)}
                        symbolClassName={
                          'text-fizz-300 line-through mr-0.5 text-xs'
                        }
                      />
                    </span>
                  )}

                  <span
                    className={`text-body-medium text-color-gray5  ${
                      isLoading ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    <PricePart
                      price={price_dash ? adjustBFPrice(price, checked) : price}
                      symbolClassName={'mr-0.5 text-xs'}
                    />
                  </span>
                  {!product.slug?.includes('xmas') && (
                    <span className={'text-caption-medium text-color-gray5'}>
                      {`${
                        price >= 10000
                          ? ''
                          : product.displayUnit
                            ? `/${product.displayUnit}`
                            : formatDuration_dash(product?.duration ?? 100800)
                      }`}
                    </span>
                  )}
                </div>

                <div className={'mt-1 flex flex-row items-center gap-1'}>
                  <div className={'relative h-3 w-3'}>
                    <Image
                      src={ClockGrayIcon}
                      fill={true}
                      className={'object-contain'}
                      alt={'Clock icon'}
                    />
                  </div>
                  <p
                    className={' text-secondary-normal truncate text-[#C8CFDE]'}
                  >
                    {estTime}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  )
}
