import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'
import { useRecoilState } from 'recoil'
import { selectedGameState } from '../../../common/atoms/atoms'
import arrowLeftIcon from '../../../public/static/card/icon_last.svg'
import arrowRightIcon from '../../../public/static/card/icon_next.svg'
import { CategoryResponse } from '../../../types/categories/responses/category-response'
import { GameTeammatesV2 } from '../../../api/creators/getGamingTeammates'
import GameCardV6 from '../GameCardV6'
import Link from 'next/link'

type Props = {
  categories: CategoryResponse[]
  gameTeammates: GameTeammatesV2
  hideIndicator?: boolean
}

export default function GameSelectorV3({ categories, gameTeammates }: Props) {
  const [game] = useRecoilState(selectedGameState)
  const [hasNext, setHasNext] = useState(true)
  const [hasPrev, setHasPrev] = useState(false)
  const swiperRef = useRef<SwiperCore>()

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    try {
      if (
        swiperRef &&
        swiperRef.current &&
        categories &&
        window.innerWidth <= 760
      ) {
        const selectedIndex = categories.findIndex(
          (category) => game?.key === category.key,
        )
        if (selectedIndex !== -1) {
          swiperRef.current.slideTo(selectedIndex)
        }
      }
    } catch (e) {}
  }, [game])

  return (
    <div className={'flex flex-row items-center gap-3.5 md:-mx-6'}>
      <div
        className={`${
          hasPrev ? 'opacity-20' : 'cursor-pointer'
        } relative mb-8 h-10 w-10`}
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <Image
          src={arrowLeftIcon}
          fill={true}
          className={'object-contain'}
          alt={'left arrow icon'}
        />
      </div>

      <Swiper
        className={'container w-screen '}
        slidesPerView={'auto'}
        centeredSlides={false}
        updateOnWindowResize={true}
        allowTouchMove={true}
        threshold={35}
        loop={false}
        onSlideChange={(swiper) => {
          setHasNext(swiper.isEnd)
          setHasPrev(swiper.isBeginning)
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
          setHasNext(swiper.isEnd)
          setHasPrev(swiper.isBeginning)
        }}
      >
        {categories?.map((category, index) => {
          if ((category?.products?.length ?? 0) === 0) {
            return null
          }

          return (
            <SwiperSlide key={category.key}>
              {(category?.products?.length ?? 0) > 0 ? (
                <Link
                  className={`${
                    category.key === game.key ? 'pointer-events-none' : ''
                  }`}
                  href={`/${category.key}/${category.seoSlug}`}
                >
                  <GameCardV6
                    key={category.key}
                    category={category}
                    isSelected={game?.key === category.key}
                    index={index}
                    enabled={(category?.products?.length ?? 0) > 0}
                    gameTeammates={gameTeammates}
                  />
                </Link>
              ) : (
                <GameCardV6
                  key={category.key}
                  category={category}
                  isSelected={game?.key === category.key}
                  index={index}
                  enabled={(category?.products?.length ?? 0) > 0}
                  gameTeammates={gameTeammates}
                />
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div
        className={`${
          hasNext ? 'opacity-20' : 'cursor-pointer'
        } relative mb-8 h-10 w-10`}
        onClick={() => swiperRef.current?.slideNext()}
      >
        <Image
          src={arrowRightIcon}
          fill={true}
          className={'object-contain'}
          alt={'right arrow icon'}
        />
      </div>
    </div>
  )
}
